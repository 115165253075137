// store.js
import { configureStore  } from '@reduxjs/toolkit'
import loginSlice from '../features/login/loginSlice';
import userSlice from '../features/login/userSlice';
import linkedinSlice from '../features/linkedin/linkedinSlice';

// Create the Redux store
const store = configureStore({
  reducer: {
    isLogged: loginSlice,
    user: userSlice,
    linkedin: linkedinSlice
  }
});

export default store;