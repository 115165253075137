import { Carousel } from "react-responsive-carousel";
import { isMobileDevice } from '../utils/func';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/Loading.scss";


const ScenarioLoading = ({ loadingValue = 15, scenario_name = "colis" }) => {
  const img1 = require("../assets/img/image_scenario/" + scenario_name + "/" + scenario_name + "_" + 1 + ".jpg");
  const img2 = require("../assets/img/image_scenario/" + scenario_name + "/" + scenario_name + "_" + 2 + ".jpg");
  const img3 = require("../assets/img/image_scenario/" + scenario_name + "/" + scenario_name + "_" + 3 + ".jpg");
  const img4 = require("../assets/img/image_scenario/" + scenario_name + "/" + scenario_name + "_" + 4 + ".jpg");

  const onChangeCarousel = () => {

  }

  const onclickeItemCarousel = () => {

  }

  const onClickThumbCarousel = () => {

  }
  
  if(isMobileDevice()){
    return (
      <div className="carousel-container" style={{ width: '100vw', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
        <Carousel
          showArrows={true}
          onChange={onChangeCarousel}
          onClickItem={onclickeItemCarousel}
          showThumbs={false}
          interval={6000}
          showStatus={false}
          stopOnHover={true}
          autoPlay={true} // Set to true for automatic play
          infiniteLoop={true} // Loop carousel items
          showIndicators={false}
          swipeable={true}
          onClickThumb={onClickThumbCarousel}>
            <div style={{ background: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img1} />
            </div>
            <div style={{ background: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img2} />
            </div>
            <div style={{ background: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img3} />
            </div>
            <div style={{ background: `url(${img4})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img4} />
            </div>
        </Carousel>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{bottom: 0}}>
            <div className="range" style={{ "--p": loadingValue + 9.8 }}>
              <div className="range__label">{(loadingValue + 9.8).toFixed(0)}%</div> {/* Display the loadingValue here */}
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return (
      <div className="carousel-container" style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
        <Carousel
          showArrows={true}
          onChange={onChangeCarousel}
          onClickItem={onclickeItemCarousel}
          showThumbs={false}
          interval={6000}
          showStatus={false}
          stopOnHover={true}
          autoPlay={true} // Set to true for automatic play
          infiniteLoop={true} // Loop carousel items
          showIndicators={false}
          swipeable={true}
          onClickThumb={onClickThumbCarousel}>
            <div style={{ background: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img1} />
            </div>
            <div style={{ background: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img2} />
            </div>
            <div style={{ background: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img3} />
            </div>
            <div style={{ background: `url(${img4})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <img alt={scenario_name} src={img4} />
            </div>
        </Carousel>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{bottom: 10}}>
            <div className="range" style={{ "--p": loadingValue + 9.8 }}>
              <div className="range__label">{(loadingValue + 9.8).toFixed(0)}%</div> {/* Display the loadingValue here */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScenarioLoading;
