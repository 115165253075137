import axios from "axios";
import { BASE_URL, X_API_KEY } from "../utils/constants";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000
})

export const updateUserRoomData = async (options) => {
  try {
    const response = await instance.post('/users/updateRoomData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserAvatarData = async (options) => {
  try {
    const response = await instance.post('/users/updateAvatarData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserDiscData = async (options) => {
  try {
    const response = await instance.post('/users/disc_profil', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserBadgesData = async (options) => {
  try {
    const response = await instance.post('/users/updateBadgesData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserEnvironmentData = async (options) => {
  try {
    const response = await instance.post('/users/updateEnvironmentData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserMythicalAnimalData = async (options) => {
  try {
    const response = await instance.post('/users/updateAnimalData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserCardData = async (options) => {
  try {
    const response = await instance.post('/users/updateCardData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserBloomiData = async (options) => {
  try {
    const response = await instance.post('/users/updateBloomiData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserLisaStateData = async (options) => {
  try {
    const response = await instance.post('/users/updateLisaStateData', options, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const retrieveCvroomData = async (id) => {
  try {
    const response = await instance.get('/users/cvroom/'.concat(id), {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}