import React, { useState } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const componentDidCatch = (error, info) => {
    // You can log the error or perform other actions here
    console.error(error, info);
    setHasError(true);
  };

  if (hasError) {
    return <div>Something went wrong.</div>;
  }

  return children;
};

export default ErrorBoundary;