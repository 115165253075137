
import '../styles/Unauthorized.css';


const Unauthorized = (props) => {

    return(
        <>
            <div fluid className="main-content-container-un px-4">
                <div className="lock">
                </div>
                <div className="message">
                <h1 className="sofraco-un-title">L'accès à cette page est restreint</h1>
                <p className="text-center">Veuillez vérifier auprès de l'administrateur du site si vous pensez qu'il s'agit d'une erreur.</p>
                </div>
            </div>
        </>
    )
}

export default Unauthorized;