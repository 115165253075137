import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import LisaNofificationComponent, { toastEmmit } from './components/LisaNotification';
import LisaRouteAuthProvider from './routes/Routes';

function App() {

  return (
    <>
      <ErrorBoundary>
        <LisaRouteAuthProvider handleToast={toastEmmit} />
      </ErrorBoundary>
      <LisaNofificationComponent />
    </>
    
  );
}

export default App;
