import React, { useState, useEffect, useRef } from 'react';
// import Navbar from "./Navbar";
import Loading from "./Loading";
import ScenarioLoading from './ScenarioLoading';
import { 
    updateUserRoomData, 
    updateUserAvatarData, 
    updateUserDiscData, 
    updateUserBadgesData,
    updateUserEnvironmentData,
    updateUserMythicalAnimalData,
    updateUserCardData,
    updateUserBloomiData,
} from '../services/UserServices';
import { isMobileDevice } from '../utils/func';
import { useNavigate, useLocation } from 'react-router-dom';

const GenieComponent = (props) => {

    const [unityLoaded, setUnityLoaded] = useState(false);
    const [loadingValue, setLoadingValue] = useState(0);
    //const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isPortrait, setIsPortrait] = useState(isMobileDevice() && window.innerHeight > window.innerWidth);
    const iframeRef = useRef(null);

    const [loadingType, setLoadingType] = useState('main'); // 'default' or 'scenario'
  const [scenarioName, setScenarioName] = useState('genie'); 

    const navigate = useNavigate();
    const loc = useLocation();

    useEffect(() => { 
        if(loc.state == undefined || loc.state == null || loc.state == ''){
            navigate("/");   
        }
    });

    useEffect(() => {
        const handleUnityLoaded = (event) => {
            if (event.data === 'UNITY_LOADED') {
              setUnityLoaded(true);
            } else if (event.data.type === 'UNITY_PROGRESS') {
              setLoadingValue(event.data.progress * 100);
            } else if (event.data.type === 'SHOW_LOADING') {
              setUnityLoaded(false);
            } else if (event.data.type === 'SET_LOADING_TYPE') {
            //   setLoadingType(event.data.loadingType);
            //   if (event.data.scenario_name) {
            //     setScenarioName(event.data.scenario_name);
            //   }
            }
        };
        
        const handleResize = () => {
            if (isMobileDevice()) {
                setIsPortrait(window.innerHeight > window.innerWidth);
            }
        };

        window.addEventListener('message', handleUnityLoaded);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('message', handleUnityLoaded);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const iframe = iframeRef.current;
    
        // Define a message event listener
        const handleMessage = (event) => {
          // Check if the message is coming from the iframe
          if (event.source === iframe.contentWindow) {
            const userData = event.data.userData;
            if(userData)
            {
                const options = {
                    _id: userData._id,
                }
                switch(event.data.methodData){
                    case 'cvroom':
                        options.roomData = userData.roomData;
                        updateUserRoomData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                    break;

                    case 'avatar':
                        options.avatarData = userData.avatarData;
                        updateUserAvatarData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                        break;

                    case 'disc':
                        options.Dominant = userData.disc.Dominant;
                        options.Influent = userData.disc.Influent;
                        options.Stable = userData.disc.Stable;
                        options.Consciencieux = userData.disc.Consciencieux;
                        updateUserDiscData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                        break;

                    case 'badge':
                        options.lostPackageBadge = userData.lostPackageBadge;
                        options.enchantedForestBadge = userData.enchantedForestBadge;
                        options.genieBadge = userData.genieBadge;
                        options.secretDoorBadge = userData.secretDoorBadge;
                        options.spaceshipBadge = userData.spaceshipBadge;
                        updateUserBadgesData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                        break;

                    case 'environment':
                        options.environmentIndex = userData.environmentIndex;
                        options.heroIndex = userData.heroIndex;
                        updateUserEnvironmentData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                        break;

                        case 'animal':
                            options.animalIndex = userData.animalIndex;
                            updateUserMythicalAnimalData(options).then((data) => {
                                if(data && data.body){
                                    localStorage.setItem('user', JSON.stringify(data.body));
                                    iframe.contentWindow.sendPlayerData();
                                }
                            }).catch((error) => {
                                console.log("Une erreur s'est produite :" + error);
                            });
                            break;

                    case 'card':
                        options.cardIndex = userData.cardIndex;
                        updateUserCardData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                        break;

                    case 'bloomi':
                        options.bloomiPosition = userData.bloomiPosition;
                        options.bloomiEuler = userData.bloomiEuler;
                        updateUserBloomiData(options).then((data) => {
                            if(data && data.body){
                                localStorage.setItem('user', JSON.stringify(data.body));
                                iframe.contentWindow.sendPlayerData();
                            }
                        }).catch((error) => {
                            console.log("Une erreur s'est produite :" + error);
                        });
                        break;
                }
            }
            else if(event.data == "disconnect"){
                localStorage.removeItem('userRole');
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                navigate('/login');
            }
            if(event.data.methodData == "changeScene"){
                console.log("Change scene to " + event.data.pathData);
                navigate(event.data.pathData,{state:{}});
            }
          }
        };
    
        // Add the message event listener
        window.addEventListener('message', handleMessage);
    
        return () => {
          // Remove the event listener when the component unmounts
          window.removeEventListener('message', handleMessage);
        };
      }, []);

      const [iframeSrc, setIframeSrc] = useState("/Genie.html"); // Initial iframe source

      return (
          <div style={{ 
              position: 'relative',
              width: '100vw', 
              height: '100vh', 
              overflow: 'hidden', 
              margin: 0, 
              padding: 0, 
              boxSizing: 'border-box' 
          }}>

            {isPortrait && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    Please rotate your device to landscape mode.
                </div>
            )}

            {/* Conditional rendering for different loading types */}
            {/* {!unityLoaded && loadingType === 'main' && <Loading loadingValue={loadingValue} />} */}
            {!unityLoaded && <ScenarioLoading loadingValue={loadingValue} scenario_name={scenarioName} />}

            <iframe
                ref={iframeRef}
                style={{ 
                    display: unityLoaded ? 'block' : 'none', 
                    width: '100%', 
                    height: '100%', 
                    border: 'none', 
                    margin: 0, 
                    padding: 0, 
                    boxSizing: 'border-box' 
                }}
                title="Genie"
                src={iframeSrc}  // Bind the iframeSrc state to the src attribute
                frameBorder="0"
                scrolling="no"
            />
        </div>
    );
}

export default GenieComponent;
