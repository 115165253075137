import { useGoogleLogin } from '@react-oauth/google';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { toastEmmit } from '../components/LisaNotification';
import { checkToken, fetchLinkedinData, fetchProfileData, login } from '../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from '../features/login/userSlice';
import { useEffect, useState } from 'react';
import { isAuth } from '../components/auth/auth';
import SpinnerLisa from '../components/Widget/Spinner';
// import { selectLinkedin } from '../features/linkedin/linkedinSlice';

const Sso = (props) => {
  
  const [isShow, setIsShow] = useState(false);
  // const [isLinkedinLogged, setIsLinkedinLogged] = useState(false);

  let linkedinCall = 0;

  // const linkedinSelect = useSelector(selectLinkedin);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      localStorage.setItem('googleToken', tokenResponse.access_token);
      await fetchProfileData(
        'https://www.googleapis.com/oauth2/v2/userinfo',
        tokenResponse.access_token,
        tokenResponse.token_type)
        .then(async (profile) => {
          const options = {
            firstName: profile?.given_name ? profile.given_name : "",
            lastName: profile?.family_name ? profile.family_name :  "",
  	    isEnabled: true,
            email: profile.email,
            picture: profile?.picture ? profile?.picture : "",
	  }
          await login(options).then((data) => {
            const userID = data.body._id

            localStorage.setItem('userRole', data.body.roles[0]);
            localStorage.setItem('user', JSON.stringify(data.body));
            localStorage.setItem('token', data.token);
            localStorage.setItem('userID', userID);
            dispatch(updateUser(data.body));
            navigate('/lisa');
          }).catch(() => {
            toastEmmit("error", "Une erreur s'est produite");
          });

        }).catch(() => {
          toastEmmit("error", "Une erreur s'est produite");
        });
      
      localStorage.setItem('googleToken', tokenResponse);
      toastEmmit("success", "Connexion avec succès");
    },
    onError: error => {
      toastEmmit("error", "Une erreur s'est produite lors du connexion avec google");
    }
  });

const fetchLinkedin = async (code) => {
    await fetchLinkedinData(code)
    .then(async (data) => {
      const userID = data.body._id

      localStorage.setItem('userRole', data.body.roles[0]);
      localStorage.setItem('user', JSON.stringify(data.body));
      localStorage.setItem('token', data.token);
      localStorage.setItem('userID', userID);
      dispatch(updateUser(data.body));
      navigate('/lisa');
      toastEmmit("success", "Connexion avec succès");
    }).catch(() => {
      toastEmmit("error", "Une erreur s'est produite");
    });
  }

  const { linkedInLogin } = useLinkedIn({
    scope: "openid,email,profile",
    clientId: process.env.REACT_APP_LINKEDIN_ID,
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: async (code) => {
      linkedinCall += 1;
      if (linkedinCall === 1) {
        fetchLinkedin(code)
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const isConnected = async () => {

    if (!isAuth()) {
      setIsShow(true);
      return;
    };

    const token = localStorage.getItem('token');
    await checkToken(token)
    .then(user => {
      setIsShow(true);
      navigate('/lisa');
    })
    .catch(err => {
      setIsShow(true);
      console.log(err)
    });
  }

  useEffect(() => {
    isConnected();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      { !isShow && ( <SpinnerLisa />  )}
      {
        isShow && (
          <div className='sso-container'>
            <div className='sso-login-container'>
              <h1 className='sso-login-title'>Se connecter</h1>
              <div className='sso-login-container-body'>
                <div className='sso-google-login'>
                  <button className='sso-google-btn' onClick={() => loginWithGoogle()}>
                    <img style={{ width: '25px' }} src='/search.png' alt='Se connecter avec google'/>
                    Se connecter avec google
                  </button>
                </div>
                <div className='sso-linkedin-login'>
                  <img
                    onClick={linkedInLogin}
                    src={linkedin}
                    alt="Se connecter avec Linked In"
                    style={{ maxWidth: '226px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default Sso;
