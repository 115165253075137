import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import Sso from "../pages/Sso";
import ErrorPage from "../pages/ErrorPage";
import NotFound404 from "../pages/404";
import Home from "../pages/Home";
import { ADMIN_ROLE, LisaRoute, PorteSecreteRoute, SECURITY_ROLE, USER_ROLE} from "../components/auth/auth";
import { AxiosInterceptor } from "../interceptor/axiosInterceptor";
import LisaComponent from "../components/Lisa";
import PorteSecreteComponent from "../components/PorteSecrete";
import ColisPerduComponent from "../components/ColisPerdu";
import GenieComponent from "../components/Genie";
import VaisseauSpatialComponent from "../components/VaisseauSpatial";
import ForetEnchanteeComponent from "../components/ForetEnchantee";
import AvatarComponent from "../components/Avatar";
import CvroomComponent from "../components/Cvroom";
import NetworkedCvroomComponent from "../components/NetworkedCvroom";
import Loading from "../components/Loading";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import ScenarioLoading from "../components/ScenarioLoading";

const LisaRouteAuthProvider = (props)  => {
  const routes = createBrowserRouter([
    {
      name: "Home",
      path: '/',
      errorElement: <ErrorPage />,
      element: <Navigate to='/login' />,
    },
    {
      name: "Loading",
      path: '/loading',
      errorElement: <ErrorPage />,
      element: <Loading />,
    },
    {
      name: "ScenarioLoading",
      path: '/scenarioloading',
      errorElement: <ErrorPage />,
      element: <ScenarioLoading />,
    },
    {
      name: "Lisa",
      path: '/lisa',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><LisaComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "PorteSecrete",
      path: '/porte-secrete',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><PorteSecreteComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "ColisPerdu",
      path: '/colis-perdu',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><ColisPerduComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "Genie",
      path: '/genie-de-la-lampe',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><GenieComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "VaisseauSpatial",
      path: '/vaisseau-spatial',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><VaisseauSpatialComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "ForetEnchantee",
      path: '/foret-enchantee',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><ForetEnchanteeComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "Avatar",
      path: '/avatar',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><AvatarComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "CVRoom",
      path: '/cvroom',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><CvroomComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "CVRoomWithID",
      path: '/cvroom/:id', // :id is a route parameter
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><NetworkedCvroomComponent handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "Sso",
      path: '/login',
      errorElement: <ErrorPage />,
      element: <Sso handleToast={props.handleToast} />,
    },
    {
      name: "Linkedin",
      path: '/linkedin',
      errorElement: <ErrorPage />,
      element: <LinkedInCallback />,
    },
    {
      name: "Home",
      path: '/home',
      errorElement: <ErrorPage />,
      element: <LisaRoute roles={[ SECURITY_ROLE, USER_ROLE, ADMIN_ROLE ]}><Home handleToast={props.handleToast} /></LisaRoute>,
    },
    {
      name: "404",
      path: '*',
      errorElement: <ErrorPage />,
      element: <NotFound404 />,
      handleToast: props.handleToast
    }
  ]);

  useEffect(() => {
    AxiosInterceptor();
  }, []);

  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default LisaRouteAuthProvider;