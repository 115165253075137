import axios from "axios";
import { BASE_URL } from "../utils/constants";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000
})

export const login = async (options) => {
  try {
    const response = await instance.post('/auth/signin', options, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchLinkedinData = async (code) => {
  const response = await instance.get('/auth/linkedin-callback/' + code, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response.data;
}

export const fetchProfileData = async (endpoint, token, token_type) => {
  const response = await axios.get(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token_type} ${token}`
    }
  })

  return response.data;
}

export const checkToken = async (token) => {
  const response = await instance.get('/auth/check-token', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })

  return response.data;
}
