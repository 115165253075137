import '../styles/404.css';

const NotFound404 = (props) => {
  return (
      <div className="body-not-found">
          <div className="container">
              <h1>Erreur 404 : Page introuvable</h1>
              <p>Désolé, la page que vous recherchez est introuvable. Veuillez réessayer ou nous contacter si vous pensez qu'il y a un problème.</p>
              <a href="/login">Retour à la page d'accueil</a>
          </div>
      </div>
  );
}

export default NotFound404;
