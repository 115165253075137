import { Carousel } from "react-responsive-carousel";
import { isMobileDevice } from '../utils/func';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/Loading.scss";


const ScenarioLoading = ({ loadingValue = 15, isCvroomReady = false }) => {
  const img1 = require("../assets/img/environnement/Image_1.jpg");
  const img2 = require("../assets/img/environnement/Image_2.jpg");
  const img3 = require("../assets/img/environnement/Image_3.jpg");
  const img4 = require("../assets/img/environnement/Image_4.jpg");
  const img5 = require("../assets/img/environnement/Image_5.jpg");
  const img6 = require("../assets/img/environnement/Image_6.jpg");
  const img7 = require("../assets/img/environnement/Sequence_01.gif");
  const img8 = require("../assets/img/environnement/Sequence_02.gif");
  const img9 = require("../assets/img/environnement/Sequence_03.gif");

  const onChangeCarousel = () => {

  }

  const onclickeItemCarousel = () => {

  }

  const onClickThumbCarousel = () => {

  }
  
  if(isMobileDevice()){
    return (
      <div className="carousel-container" style={{ width: '100vw', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
        {
          isCvroomReady ? (
            <Carousel
              showArrows={true}
              onChange={onChangeCarousel}
              onClickItem={onclickeItemCarousel}
              showThumbs={false}
              interval={8000}
              showStatus={false}
              stopOnHover={false}
              autoPlay={true} // Set to true for automatic play
              infiniteLoop={true} // Loop carousel items
              showIndicators={false}
              swipeable={true}
              onClickThumb={onClickThumbCarousel}>
                <div style={{ background: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Accueil" src={img1} />
                </div>
                <div style={{ background: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Description de Bloomii" src={img2} />
                </div>
                <div style={{ background: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Astuce" src={img3} />
                </div>
                <div style={{ background: `url(${img4})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Badge" src={img4} />
                </div>
                <div style={{ background: `url(${img5})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="cvroom 1" src={img5} />
                </div>
                <div style={{ background: `url(${img6})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="cvroom 2" src={img6} />
                </div>
                <div style={{ background: `url(${img9})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Joystick" src={img9} />
                </div>
            </Carousel>
          ) : (
            <Carousel
              showArrows={true}
              onChange={onChangeCarousel}
              onClickItem={onclickeItemCarousel}
              showThumbs={false}
              interval={8000}
              showStatus={false}
              stopOnHover={false}
              autoPlay={true} // Set to true for automatic play
              infiniteLoop={true} // Loop carousel items
              showIndicators={false}
              swipeable={true}
              onClickThumb={onClickThumbCarousel}>
                <div style={{ background: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Accueil" src={img1} />
                </div>
                <div style={{ background: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Description de Bloomii" src={img2} />
                </div>
                <div style={{ background: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Astuce" src={img3} />
                </div>
                <div style={{ background: `url(${img4})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Badge" src={img4} />
                </div>
                <div style={{ background: `url(${img9})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Joystick" src={img9} />
                </div>
            </Carousel>
          )
        }
        <div className="progress-bar-container">
          <div className="progress-bar" style={{bottom: 0}}>
            <div className="range" style={{ "--p": loadingValue + 9.8 }}>
              <div className="range__label">{(loadingValue + 9.8).toFixed(0)}%</div> {/* Display the loadingValue here */}
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return (
      <div className="carousel-container" style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
        {
          isCvroomReady ? (
            <Carousel
              showArrows={true}
              onChange={onChangeCarousel}
              onClickItem={onclickeItemCarousel}
              showThumbs={false}
              interval={8000}
              showStatus={false}
              stopOnHover={false}
              autoPlay={true} // Set to true for automatic play
              infiniteLoop={true} // Loop carousel items
              showIndicators={false}
              swipeable={true}
              onClickThumb={onClickThumbCarousel}>
                <div style={{ background: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Accueil" src={img1} />
                </div>
                <div style={{ background: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Description" src={img2} />
                </div>
                <div style={{ background: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Astuce" src={img3} />
                </div>
                <div style={{ background: `url(${img4})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Badge" src={img4} />
                </div>
                <div style={{ background: `url(${img5})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="cvroom 1" src={img5} />
                </div>
                <div style={{ background: `url(${img6})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="cvroom 2" src={img6} />
                </div>
                <div style={{ background: `url(${img7})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Touche directionnelle" src={img7} />
                </div>
                <div style={{ background: `url(${img8})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Navigation souris" src={img8} />
                </div>
            </Carousel>
          ) : (
            <Carousel
              showArrows={true}
              onChange={onChangeCarousel}
              onClickItem={onclickeItemCarousel}
              showThumbs={false}
              interval={8000}
              showStatus={false}
              stopOnHover={false}
              autoPlay={true} // Set to true for automatic play
              infiniteLoop={true} // Loop carousel items
              showIndicators={false}
              swipeable={true}
              onClickThumb={onClickThumbCarousel}>
                <div style={{ background: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Accueil" src={img1} />
                </div>
                <div style={{ background: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Description" src={img2} />
                </div>
                <div style={{ background: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Astuce" src={img3} />
                </div>
                <div style={{ background: `url(${img4})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Badge" src={img4} />
                </div>
                <div style={{ background: `url(${img7})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Touche directionnelle" src={img7} />
                </div>
                <div style={{ background: `url(${img8})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img alt="Navigation souris" src={img8} />
                </div>
            </Carousel>
          )
        }
        <div className="progress-bar-container">
          <div className="progress-bar" style={{bottom: 10}}>
            <div className="range" style={{ "--p": loadingValue + 9.8 }}>
              <div className="range__label">{(loadingValue + 9.8).toFixed(0)}%</div> {/* Display the loadingValue here */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScenarioLoading;
