import axios from 'axios';

export const AxiosInterceptor = () => axios.interceptors.response.use(
    function (successRes) {
        return successRes;
    },
    function (error) {
        const match = String(error)?.match('401');
        if (match !== null) {
            if (match[0] === '401') {
                document.cookie = "sofraco=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                console.log('Déconnexion');
                localStorage.clear();
                window.location.reload();
            }
        }
        return Promise.reject(error);
    }
);