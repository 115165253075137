import '../../styles/Widget/Spinner.css';

const SpinnerLisa = (props) => {
  return(
    <div className="spinner-sso">
      <span className="loader"></span>
    </div>
  )
}

export default SpinnerLisa;